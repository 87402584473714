//
// Web Components.
// Ripple.
// -------------------

import { emitEvent } from '../web-components/utils.js'

const WCRipple = ((document, window) => {

  const NAME = 'ripple'

  const ANIMATION_CLASS = {
    in: `${NAME}--animate-in`,
    out: `${NAME}--animate-out`
  }

  const CSS_VAR = {
    left: `--${NAME}-left`,
    scale: `--${NAME}-scale`,
    size: `--${NAME}-size`,
    offset: `--${NAME}-offset`,
    top: `--${NAME}-top`
  }

  const EVENT_NAME = {
    end: `${NAME}:ended`,
    start: `${NAME}:started`
  }

  const END_EVENTS = [
    'keyup',
    'mouseup',
    'mouseleave',
    'touchend'
  ]

  const START_EVENTS = [
    'keydown',
    'mousedown',
    'touchstart'
  ]

  const EVENT_CODES = [
    'Enter',
    'Space'
  ]

  function checkBoundValue (bound, parent) {
    if (bound) return bound
  
    return parent.dataset.ripple === 'bound'
  }

  function createRippleContainer () {
    const r = document.createElement('span')
    r.classList.add(NAME)
    r.setAttribute('aria-hidden', 'true')

    return r
  }

  function eventNormalization (event) {
    return event.type === 'mousedown'
      || event.type === 'keydown'
      ? event : event.touches[0]
  }

  function getOffsetPosition(event, bound) {
    const {
      clientX, clientY, target, type
    } = eventNormalization(event)
    const { top, left } = target.getBoundingClientRect()
    let w = target.offsetWidth
    let h = target.offsetHeight
    let x = bound || type === 'keydown'
      ? w / 2 : clientX - left
    let y = bound || type === 'keydown'
      ? h / 2 : clientY - top

    return { h, w, x, y }
  }

  function getOffsetScaleAndSizeFromEvent (event, bound = false) {
    let { h, w, x, y } = getOffsetPosition(event, bound)
    let dx = (w / 2) + Math.abs((w / 2) - x)
    let dy = (h / 2) + Math.abs((h / 2) - y)
    let size = Math.max(w, h) * .3
    let scale = (Math.sqrt(Math.pow(dx, 2) + Math.pow(dy, 2)) - size) * .2
    let offset = -1 * size / 2

    return { offset, scale, size, x, y }
  }
          
  return class Ripple {

    constructor(root, bound = false) {
      this.root = root
      this.bound = checkBoundValue(bound, this.root)
      this.ripple = createRippleContainer()
      this._is_animating = false

      this.root.appendChild(this.ripple)

      this._animateEventHandler = event => this.animateHandler_(event)

      START_EVENTS.forEach(
        e => this.root.addEventListener(
          e, this._animateEventHandler, false
        )
      )
    }

    animateHandler_(event) {
      if (
        this._is_animating || (
          event.type === 'keydown' &&
          ! EVENT_CODES.includes(event.code)
        )
      ) return

      this._is_animating = true
      this.animateIn_(event)

      const onMouseUpOrLeave = () => {
        this.ripple.dataset.ripple = 'off'
  
        if (this.ripple.dataset.animate === 'off')
          this.animateOut_()
      }

      END_EVENTS.forEach(
        e => this.root.addEventListener(e, onMouseUpOrLeave, {
          once: true
        })
      )
    }

    animateIn_(event) {
      const {
        offset, scale, size, x, y
      } = getOffsetScaleAndSizeFromEvent(event, this.bound)

      this.ripple.style.setProperty(CSS_VAR.top, `${y}px`)
      this.ripple.style.setProperty(CSS_VAR.left, `${x}px`)
      this.ripple.style.setProperty(CSS_VAR.size, `${size}px`)
      this.ripple.style.setProperty(CSS_VAR.scale, scale)
      this.ripple.style.setProperty(CSS_VAR.offset, `${offset}px`)

      this.ripple.dataset.animate = 'on'
      this.ripple.dataset.ripple = 'on'

      this.ripple.classList.add(ANIMATION_CLASS.in)

      emitEvent(this.root, EVENT_NAME.start)

      const onAnimationEnd = () => {
        this.ripple.dataset.animate = 'off'
  
        if (this.ripple.dataset.ripple === 'off')
          this.animateOut_()
      }

      this.ripple.addEventListener('animationend', onAnimationEnd, {
        once: true
      })
    }

    animateOut_() {
      this.ripple.classList.replace(
        ANIMATION_CLASS.in,
        ANIMATION_CLASS.out
      )

      const onAnimationEnd = () => {
        this._is_animating = false
        this.ripple.classList.remove(ANIMATION_CLASS.out)
        emitEvent(this.root, EVENT_NAME.end)
      }

      this.ripple.addEventListener('animationend', onAnimationEnd, {
        once: true
      })
    }
  
  }

})(document, window)

export { WCRipple }
