//
// Web Components.
// Appbar.
// -------------------

const WCAppbar = ((window) => {

  const NAME = 'appbar'

  const MAX_APPBAR_HEIGHT = 128

  const HAS_SHADOW = `${NAME}--has-shadow`

  return class Appbar {

    constructor(root) {
      this.root = root

      this.scrolled_ = false
      this.hasShadow_ = this.root.classList.contains(HAS_SHADOW)
      this.wasDocked_ = true
      this.appbarHeight_ = this.appbarHeight()
      this.isDockedShowing_ = true
      this.resizeThrottleId_ = 0
      this.resizeDebounceId_ = 0
      this.lastScrollPosition_ = window.pageYOffset
      this.currentAppBarOffsetTop_ = 0
      this.isCurrentlyBeingResized_ = false

      this.scrollEventHandler_ = () => this.scrollHandler()
      this.resizeEventHandler_ = () => this.throttleResizeHandler()

      window.addEventListener('scroll', this.scrollEventHandler_)
      window.addEventListener('resize', this.resizeEventHandler_)
    }

    appbarHeight() {
      return this.root.clientHeight
    }

    appbarOnScreen() {
      const offscreenBoundaryTop = -this.appbarHeight_
      const hasAnyPixelsOffscreen = this.currentAppBarOffsetTop_ < 0
      const hasAnyPixelsOnscreen = this.currentAppBarOffsetTop_ > offscreenBoundaryTop
      const partiallyShowing = hasAnyPixelsOffscreen && hasAnyPixelsOnscreen

      if (partiallyShowing) {
        this.wasDocked_ = false
      } else {
        if ( ! this.wasDocked_) {
          this.wasDocked_ = true
          return true
        } else if (this.isDockedShowing_ !== hasAnyPixelsOnscreen) {
          this.isDockedShowing_ = hasAnyPixelsOnscreen
          return true
        }
      }

      return partiallyShowing
    }

    moveAppbar() {
      if (this.appbarOnScreen()) {
        let offset = this.currentAppBarOffsetTop_

        if (this.hasShadow_) this.setShadow(offset)

        if (Math.abs(offset) >= this.appbarHeight_)
          offset = -MAX_APPBAR_HEIGHT

        this.root.style.transform = `translateY(${offset}px)`
      }
    }

    resizeHandler() {
      const currentHeight = this.appbarHeight()

      if (this.appbarHeight_ !== currentHeight) {
        this.wasDocked_ = false

        this.currentAppBarOffsetTop_ -= this.appbarHeight_ - currentHeight
        this.appbarHeight_ = currentHeight
      }

      this.scrollHandler()
    }

    scrollHandler() {
      const currentScrollPosition = Math.max(window.pageYOffset, 0)
      const diff = currentScrollPosition - this.lastScrollPosition_
      let scroll = window.pageYOffset
      this.lastScrollPosition_ = currentScrollPosition

      if ( ! this.isCurrentlyBeingResized_) {
        this.currentAppBarOffsetTop_ -= diff

        if (this.currentAppBarOffsetTop_ > 0)
          this.currentAppBarOffsetTop_ = 0
        else if (Math.abs(this.currentAppBarOffsetTop_) > this.appbarHeight_)
          this.currentAppBarOffsetTop_ = -this.appbarHeight_

        if (scroll <= 0) {
          if (this.scrolled_)
            this.scrolled_ = false
        } else {
          if ( ! this.scrolled_)
            this.scrolled_ = true
        }

        this.moveAppbar()
      }
    }

    setShadow(offset) {
      if ( ! this.scrolled_ || ! offset)
        return false

      const h = this.appbarHeight()

      if (Math.abs(offset) <= h)
        this.root.classList.add(HAS_SHADOW)

      if (Math.abs(offset) >= h)
        this.root.classList.remove(HAS_SHADOW)
    }

    throttleResizeHandler() {
      if ( ! this.resizeThrottleId_) {
        this.resizeThrottleId_ = window.setTimeout(() => {
          this.resizeThrottleId_ = 0
          this.resizeHandler()
        }, 100)
      }

      this.isCurrentlyBeingResized_ = true

      if (this.resizeDebounceId_)
        window.clearTimeout(this.resizeDebounceId_)

      this.resizeDebounceId_ = window.setTimeout(() => {
        this.resizeHandler()
        this.isCurrentlyBeingResized_ = false
        this.resizeDebounceId_ = 0
      }, 100)
    }

  }

  })(window)

export { WCAppbar }
