//
// Web Components.
// Tooltip.
// -------------------

import { emitEvent } from '../web-components/utils.js'

const WCTooltip = ((document, window) => {

  const NAME = 'tooltip'

  const ACTIVE_STATE = `${NAME}--is-visible`

  const EVENT_NAME = {
    hidden: `${NAME}:hidden`,
    hide: `${NAME}:hide`,
    show: `${NAME}:show`,
    showing: `${NAME}:showing`
  }

  return class Tooltip {

    static get events() {
      return [
        { 'enter': 'mouseenter', 'leave': 'mouseleave' },
        { 'enter': 'touchstart', 'leave': 'touchend' },
        { 'enter': 'focusin', 'leave': 'focusout' }
      ]
    }

    constructor(root) {
      this.root = root
      this.tooltip = null

      this.fixed_     = this.root.hasAttribute('data-fixed')
      this.content_   = this.root.title || null
      this.placement_ = this.root.getAttribute('data-placement') || 'top'
      this.isVisible_ = null

      this.root.setAttribute('tabindex', '0')

      this.enterHandler = () => this.open()
      this.leaveHandler = () => this.close()

      for (let i = Tooltip.events.length; i--;) {
        let event = Tooltip.events[i]
        this.root.addEventListener(event.enter, this.enterHandler)
        this.root.addEventListener(event.leave, this.leaveHandler)
      }
    }

    close() {
      if (
        this.tooltip && (
          ! this.tooltip.classList.contains(ACTIVE_STATE) ||
          this.isVisible_ === 'out'
        )
      ) {
        this.isVisible_ = 'out'
        return false
      }

      this.isVisible_ = 'out'

      return this.hide()
    }

    open() {
      if (
        this.tooltip && (
          this.tooltip.classList.contains(ACTIVE_STATE) ||
          this.isVisible_ === 'in'
        )
      ) {
        this.isVisible_ = 'in'
        return false
      }

      this.isVisible_ = 'in'

      return this.show()
    }

    destroy() {
      if (
        this.tooltip &&
        this.tooltip.classList.contains(ACTIVE_STATE)
      ) this.hide()

      for (let i = Tooltip.events.length; i--;) {
        let event = Tooltip.events[i]
        this.root.removeEventListener(event.enter, this.enterHandler)
        this.root.removeEventListener(event.leave, this.leaveHandler)
      }

      this.root.removeAttribute('tabindex')

      this.root      = null
      this.tooltip      = null
      this.content_     = null
      this.placement_   = null
      this.isVisible_   = null
      this.enterHandler = null
      this.leaveHandler = null
    }

    hide() {
      if ( ! this.tooltip) return false

      this.tooltip.classList.remove(ACTIVE_STATE)

      emitEvent(this.root, EVENT_NAME.hide)

      const hide = () => {
        this.root.setAttribute('title', this.content_)
        this.root.removeAttribute('aria-describedby')
        this.root.removeAttribute('data-title')
        document.body.removeChild(this.tooltip)
        emitEvent(this.root, EVENT_NAME.hidden)
        this.tooltip = null
      }

      this.tooltip.addEventListener('transitionend', hide, {
        once: true
      })

      this.isVisible_ = null
    }

    show() {
      this.tooltip = this.createTooltip()

      if ( ! this.tooltip) return false

      emitEvent(this.root, EVENT_NAME.show, { origin: this.tooltip })

      const id = this.generateID()

      this.tooltip.id = id
      this.root.setAttribute('aria-describedby', id)
      this.root.setAttribute('data-title', this.content_)
      this.root.removeAttribute('title')

      let placement = this.placement_
      this.tooltip.classList.add(`${NAME}--${placement}`)
      document.body.appendChild(this.tooltip)

      let position      = this.getPosition()
      let actualWidth   = this.tooltip.offsetWidth
      let actualHeight  = this.tooltip.offsetHeight

      placement = this.setPlacement(placement, position, actualWidth, actualHeight)
      let offset = this.getOffset(placement, position, actualWidth, actualHeight)

      this.applyOffset(offset, placement)

      const show = () => {
        let visible = this.isVisible_
        this.isVisible_ = null

        if (visible === 'out') this.close()
        emitEvent(this.root, EVENT_NAME.showing, { origin: this.tooltip })

        window.addEventListener('scroll', () => this.close(), { once: true })
      }

      this.tooltip.addEventListener('transitionend', show, {
        once: true
      })

      this.tooltip.classList.add(ACTIVE_STATE)
    }

    applyOffset(offset, placement) {
      let tip     = this.tooltip
      let width   = tip.offsetWidth
      let height  = tip.offsetHeight
      let style   = window.getComputedStyle(tip)

      let marginTop   = parseInt(style.marginTop, 10)
      let marginLeft  = parseInt(style.marginLeft, 10)

      if (isNaN(marginTop))  marginTop  = 0
      if (isNaN(marginLeft)) marginLeft = 0

      offset.top  += marginTop
      offset.left += marginLeft

      let actualWidth   = tip.offsetWidth
      let actualHeight  = tip.offsetHeight

      if (placement === 'top' && actualHeight !== height)
        offset.top = offset.top + marginTop + height - actualHeight

      if (placement === 'left' && actualWidth !== width)
        offset.left = offset.left + marginLeft + width - actualWidth

      let delta = this.getViewportDelta(
        placement, offset,
        actualWidth, actualHeight
      )

      if (delta.left)
        offset.left += delta.left
      else offset.top += delta.top

      tip.style.top   = `${Math.round(offset.top)}px`
      tip.style.left  = `${Math.round(offset.left)}px`
    }

    createTooltip() {
      const tip = document.createElement('div')
      tip.classList.add(NAME)
      tip.setAttribute('role', 'tooltip')
      if ( ! this.content_) return false
      tip.textContent = this.content_

      return tip
    }

    generateID() {
      let dec = dec => ('0' + dec.toString(16)).substr(-2)
      let arr = (window.crypto || window.msCrypto).getRandomValues(new Uint8Array(10 / 2))
      let id  = 'tooltip-' + Array.from(arr, dec).join('')
      if (document.getElementById(id)) id = this.generateID()
      return id
    }

    getBoundingClientRect(element) {
      const rect = element.getBoundingClientRect()

      if (rect.width === null) {
        rect.width  = rect.right - rect.left
        rect.height = rect.bottom - rect.top
      }

      return {
        top: rect.top, right: rect.right,
        bottom: rect.bottom, left: rect.left,
        width: rect.width, height: rect.height,
        x: rect.x, y: rect.y
      }
    }

    getOffset(placement, position, actualWidth, actualHeight) {
      let offset = null

      if (placement === 'bottom') {
        offset = {
          top: position.top + position.height,
          left: position.left + position.width / 2 - actualWidth / 2
        }
      } else if (placement === 'top') {
        offset = {
          top: position.top - actualHeight,
          left: position.left + position.width / 2 - actualWidth / 2
        }
      } else if (placement === 'left') {
        offset = {
          top: position.top + position.height / 2 - actualHeight / 2,
          left: position.left - actualWidth
        }
      } else {
        offset = {
          top: position.top + position.height / 2 - actualHeight / 2,
          left: position.left + position.width
        }
      }

      return offset
    }

    getPosition(element = false) {
      element = element || this.root

      let body = element.tagName === 'BODY'
      let rect = this.getBoundingClientRect(element)
      let scrollY = this.fixed_ ? (window.scrollY || window.pageYOffset) : 0

      let offset = {
        top: body ? 0 : element.offsetTop + scrollY,
        left: body ? 0 : element.offsetLeft
      }

      let scroll = {
        scroll: body ? document.body.scrollTop : element.scrollTop
      }

      let outer = body ? {
        width: Math.min(document.body.clientWidth, window.outerWidth),
        height: Math.min(document.body.clientHeight, window.outerHeight)
      } : {}

      return Object.assign({}, rect, scroll, outer, offset)
    }

    getViewportDelta(placement, position, actualWidth, actualHeight) {
      let delta               = { top: 0, left: 0 }
      let viewportPadding     = document.body.padding || 0
      let viewportDimensions  = this.getPosition(document.body)

      if (/right|left/.test(placement)) {
        let topEdgeOffset     = position.top - viewportPadding - viewportDimensions.scroll
        let bottomEdgeOffset  = position.top + viewportPadding - viewportDimensions.scroll + actualHeight

        if (topEdgeOffset < viewportDimensions.top) {
          delta.top = viewportDimensions.top - topEdgeOffset
        } else if (bottomEdgeOffset > viewportDimensions.top + viewportDimensions.height) {
          delta.top = viewportDimensions.top + viewportDimensions.height - bottomEdgeOffset
        }
      } else {
        let leftEdgeOffset  = position.left - viewportPadding
        let rightEdgeOffset = position.left + viewportPadding + actualWidth

        if (leftEdgeOffset < viewportDimensions.left) {
          delta.left = viewportDimensions.left - leftEdgeOffset
        } else if (rightEdgeOffset > viewportDimensions.right) {
          delta.left = viewportDimensions.left + viewportDimensions.width - rightEdgeOffset
        }
      }

      return delta
    }

    setPlacement(placement, position, actualWidth, actualHeight) {
      let original = placement
      let viewport = this.getPosition(document.body)
      // let style    = window.getComputedStyle(this.tooltip)
      // window.getComputedStyle(this.tooltip)

      // let marginTop   = parseInt(style.marginTop, 10)
      // let marginLeft  = parseInt(style.marginLeft, 10)

      // Remove tooltip so we can swap the positional
      // class and allow transforms to act accordingly.
      document.body.removeChild(this.tooltip)

      if (placement === 'bottom' && position.bottom + actualHeight > viewport.bottom) {
        placement = 'top'
      } else if (placement === 'top' && position.top - actualHeight < viewport.top) {
        placement = 'bottom'
      } else if (placement === 'right' && position.right + actualWidth > viewport.width) {
        placement = 'left'
      } else if (placement === 'left' && position.left - actualWidth < viewport.left) {
        placement = 'right'
      } else {
        placement = original
      }

      this.tooltip.classList.remove(`${NAME}--${original}`)
      this.tooltip.classList.add(`${NAME}--${placement}`)

      // Re-append tooltip so the directional
      // transform transition works correctly.
      document.body.appendChild(this.tooltip)

      return placement
    }

  }

})(document, window)

export { WCTooltip }
