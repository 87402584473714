//
// Web Components.
// Icon Button.
// -------------------

const WCIconButton = (() => {

  return class IconButton {

    constructor(root) {
      this.root = root
      this.dataset = this.root.dataset
      this.label = this.root.getAttribute('aria-label')
      this.pressed = null

      if (this.dataset.onIcon && this.dataset.offIcon)
        this.root.addEventListener('click', () => this.toggleIcon())
    }

    iconTarget(target, search) {
      if (search.classList.contains(target))
        return search

      target = search.querySelector(target)

      if (target) return target

      return false
    }

    toggleIcon() {
      if (
        ( ! this.dataset.onIcon && ! this.dataset.offIcon) ||
        ! this.root.hasAttribute('aria-label') ||
        this.root.hasAttribute('disabled')
      ) return false

      let icon = this.iconTarget(this.dataset.target, this.root)
      let text = this.root.textContent

      if ( ! icon) return false

      if (icon.classList.contains(this.dataset.onIcon))
        icon.classList.replace(
          this.dataset.onIcon,
          this.dataset.offIcon
        )
      else if (icon.classList.contains(this.dataset.offIcon))
        icon.classList.replace(
          this.dataset.offIcon,
          this.dataset.onIcon
        )

      if (text === this.dataset.onLabel)
        this.root.textContent = this.dataset.offIcon
      else if (text === this.dataset.offLabel)
        this.root.textContent = this.dataset.onIcon

      if (this.label === this.dataset.onLabel) {
        this.label = this.dataset.offLabel
        this.pressed = 'false'
      } else if (this.label === this.dataset.offLabel) {
        this.label = this.dataset.onLabel
        this.pressed = 'true'
      }

      this.root.setAttribute('aria-label', this.label)
      this.root.setAttribute('aria-pressed', this.pressed)
    }

  }

})()

export { WCIconButton }
