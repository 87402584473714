//
// Web Components.
// Textfield.
// -------------------

import { emitEvent } from '../web-components/utils.js'

const WCTextfield = (() => {

  const NAME = 'textfield'

  const EVENT_NAME = {
    blur: `${NAME}:blur`,
    focus: `${NAME}:focus`
  }

  const STATE_CLASS = {
    dirty: `${NAME}--is-dirty`,
    disabled: `${NAME}--is-disabled`,
    focus: `${NAME}--has-focus`
  }

  return class Textfield {

    constructor(root) {
      this.root = root
      this.input = this.root.querySelector('input, textarea')

      if (this.isDirty())
        this.root.classList.add(STATE_CLASS.dirty)

      if (this.isDisabled())
        this.root.classList.add(STATE_CLASS.disabled)

      this.input.addEventListener('blur', () => this.blurHandler())
      this.input.addEventListener('focus', () => this.focusHandler())
    }

    blurHandler() {
      if (this.isDisabled()) return false

      this.root.classList.remove(STATE_CLASS.focus)

      if ( ! this.isDirty())
        this.root.classList.remove(STATE_CLASS.dirty)
      else
        this.root.classList.add(STATE_CLASS.dirty)

      emitEvent(this.root, EVENT_NAME.blur)
    }

    focusHandler() {
      if (this.isDisabled()) return false

      this.root.classList.add(STATE_CLASS.focus)

      emitEvent(this.root, EVENT_NAME.focus)
    }

    isDirty() {
      return this.input.value !== ''
    }

    isDisabled() {
      return !! this.input.disabled
    }

  }

})()

export { WCTextfield }
