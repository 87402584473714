//
// Web Components.
// Scroll Top.
// -------------------

const wcScrollTop = ((document, window) => {

  const NAME = 'scroll-top'

  const FOCUS_ELEMENTS = [
    'a[href]',
    'area[href]',
    'button:not([disabled])',
    'input:not([disabled])',
    'select:not([disabled])',
    'textarea:not([disabled])',
    '[tabindex]:not([tabindex="-1"])'
  ].join(',')

  function animateScroll (final, duration) {
    let start = window.scrollY || document.documentElement.scrollTop
    let current = null
    let focusable = document.querySelectorAll(FOCUS_ELEMENTS)[0]

    const animate = function (timestamp) {
      if ( ! current) current = timestamp       
      let progress = timestamp - current
      if (progress > duration) progress = duration
      let value = easingCurve(progress, start, final - start, duration)
      window.scrollTo(0, value)
      if (progress < duration)
        window.requestAnimationFrame(animate)
      if (value === 0) focusable.focus()
    }

    window.requestAnimationFrame(animate)
  }

  function easingCurve (t, b, c, d) {
    t /= d / 2
    if (t < 1) return c / 2 * t * t + b
    t--
    return -c / 2 * (t * (t - 2) - 1) + b
  }

  return function scrollTop (root) {
    if ( ! root) return false

    let scrolling = false

    const duration = parseInt(root.dataset.scrollDuration, 10) || 480
    const offset = parseInt(root.dataset.scrollOffset, 10) || 100

    const showToTopButton = function () {
      const top = window.scrollY || document.documentElement.scrollTop
      if (top >= offset) root.classList.add(`${NAME}--visible`)
      else root.classList.remove(`${NAME}--visible`)
      scrolling = false
    }

    root.addEventListener('click', function (event) {
      if (event) event.preventDefault()

      return ( ! window.requestAnimationFrame) ?
        window.scrollTo(0, 0) : animateScroll(0, duration)
    })

    if (offset > 0) window.addEventListener('scroll', function () {
      if ( ! scrolling ) {
        scrolling = true
        return ( ! window.requestAnimationFrame) ?
          window.setTimeout(showToTopButton, 250) :
          window.requestAnimationFrame(showToTopButton)
      }
    })

    showToTopButton()
  }

})(document, window)

export { wcScrollTop }
