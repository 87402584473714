//
// Web Components.
// Swipe.
// -------------------

import { emitEvent } from '../web-components/utils.js'

const WCSwipe = ((document, window) => {

  const NAME = 'swipe'

  const END_EVENTS = [
    'mouseup',
    'mouseleave',
    'touchend'
  ]

  const MOVE_EVENTS = [
    'mousemove',
    'touchmove'
  ]

  const START_EVENTS = [
    'mousedown',
    'touchstart'
  ]

  const EVENT_NAME = {
    down: `${NAME}:down`,
    end: `${NAME}:end`,
    left: `${NAME}:left`,
    move: `${NAME}:move`,
    right: `${NAME}:right`,
    start: `${NAME}:start`,
    up: `${NAME}:up`
  }

  const unify = event => event.changedTouches ? event.changedTouches[0] : event

  return class Swipe {

    constructor(root) {
      this.root = root
      this.delta_ = { x: false, y: false }
      this.swiping_ = false
      this.requestFrame_ = false

      if ( ! this.root) return

      this.swipeEventHandler_ = event => this.swipeHandler_(event)

      START_EVENTS.forEach(
        type => this.root.addEventListener(type, this.swipeEventHandler_)
      )
    }

    swipeHandler_(event) {
      if (END_EVENTS.includes(event.type))
        return this.swipeEnd_(event)

      if (MOVE_EVENTS.includes(event.type))
        return this.swipeMove_(event)

      if (START_EVENTS.includes(event.type))
        return this.swipeStart_(event)
    }

    swipeMove_(event) {
      if ( ! this.swiping_) return

      const e = unify(event)
      const x = parseInt(e.clientX, 10)
      const y = parseInt(e.clientY, 10)

      this.requestFrame_ = window.requestAnimationFrame(() => emitEvent(
        this.root, EVENT_NAME.move,
        { x, y, origin : event.target }
      ))
    }

    swipeEnd_(event) {
      if (this.requestFrame_) {
        window.cancelAnimationFrame(this.requestFrame_)
        this.requestFrame_ = false
      }

      ;[...END_EVENTS, ...MOVE_EVENTS].forEach(
        type => this.root.removeEventListener(type, this.swipeEventHandler_)
      )

      const e = unify(event)
      const y = parseInt(e.clientY, 10)
      const x = parseInt(e.clientX, 10)

      if (
        this.delta_ &&
        (this.delta_.x || this.delta_.x === 0)
      ) {
        let s = Math.sign(x - this.delta_.x)

        if (Math.abs(x - this.delta_.x) > 30) emitEvent(
          this.root, EVENT_NAME[s < 0 ? 'left' : 'right'],
          { x, y, origin : event.target }
        )

        this.delta_.x = false
      }

      if (
        this.delta_ &&
        (this.delta_.y || this.delta_.y === 0)
      ) {
        let s = Math.sign(y - this.delta_.y)
  
        if (Math.abs(y - this.delta_[1]) > 30) emitEvent(
          this.root, EVENT_NAME[s < 0 ? 'up' : 'down'],
          { x, y, origin : event.target }
        )
  
        this.delta_.y = false
      }

      emitEvent(this.root, EVENT_NAME.end, { x, y, origin : event.target })

      this.swiping_ = false
    }

    swipeStart_(event) {
      this.swiping_ = true

      const e = unify(event)
      const x = this.delta_.x = parseInt(e.clientX, 10)
      const y = this.delta_.y = parseInt(e.clientY, 10)

      ;[...END_EVENTS, ...MOVE_EVENTS].forEach(
        type => this.root.addEventListener(type, this.swipeEventHandler_)
      )

      emitEvent(this.root, EVENT_NAME.start, { x, y, origin : event.target })
    }

  }

})(document, window)

export { WCSwipe }
