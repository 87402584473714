//
// Web Components.
// Global js Import.
// -------------------

import { WCAppbar } from './appbar/appbar.js'
import { WCRipple } from './ripple/ripple.js'
import { WCTooltip } from './tooltip/tooltip.js'
import { WCTextfield } from './textfield/textfield.js'
import { wcScrollTop } from './scroll-top/scroll-top.js'
import { WCIconButton } from './button/icon-button.js'
import { WCCarousel } from './carousel/carousel.js'
import { WCParticleButton } from './particle/particle-button.js'

document.fonts.onloadingdone = () => document.documentElement.classList.add('fonts-loaded')

;(document => {
  document.documentElement.classList.add('document-ready')
  new WCAppbar(document.querySelector('.appbar'))
  new WCCarousel(document.querySelector('.carousel'))
  ;[...document.querySelectorAll('[data-ripple]')]
    .forEach(r => new WCRipple(r, r.classList.contains('icon-button')))
  ;[...document.querySelectorAll('[title]')]
    .forEach(t => new WCTooltip(t))
  ;[...document.querySelectorAll('.textfield')]
    .forEach(t => new WCTextfield(t))
  ;[...document.querySelectorAll('.icon-button')]
    .forEach(b => new WCIconButton(b))
  ;[...document.querySelectorAll('[data-particles]')]
    .forEach(p => new WCParticleButton(p))
  wcScrollTop(document.querySelector('.scroll-top'))
})(document)
