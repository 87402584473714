//
// Web Components.
// Particle.
// -------------------

import { extend } from '../web-components/utils.js'

const WCParticle = ((document) => {

  const DEFAULT_CONFIG = {
    animateClass: 'is-animating',
    delay: 60,
    duration: { max: 720, min: 480 },
    hueRotation: { max: 360, min: 0 },
    size: { max: 15, min: 7 },
    translate: { x: 180, y: 180}
  }

  return class Particle {

    constructor(root, config = {}) {
      this.root = root
      this.config = extend(DEFAULT_CONFIG, config)
    }

    animate(x, y) {
      const particle = this.create(x, y)
      particle.classList.add(this.config.animateClass)
      particle.addEventListener('animationend', () => particle.remove(), { once: true })
    }

    create(x, y) {
      const particle = document.createElement('particle')
      const style = particle.style
      const size = this.getRandomNumberBetween(this.config.size.min, this.config.size.max)

      particle.setAttribute('aria-hidden', 'true')

      style.setProperty('--particle-size', `${size}px`)
      style.setProperty('--particle-top', `${y - (size / 2)}px`)
      style.setProperty('--particle-left', `${x - (size / 2)}px`)
      style.setProperty('--particle-hue-rotation', this.getRandomNumberBetween(this.config.hueRotation.min, this.config.hueRotation.max))
      style.setProperty('--particle-animation-delay', `${this.getRandomNumberUpTo(this.config.delay)}ms`)
      style.setProperty('--particle-animation-duration', `${this.getRandomNumberBetween(this.config.duration.min, this.config.duration.max)}ms`)
      style.setProperty('--particle-translate-x', `${this.getRandomNumberPlusMinusUpTo(this.config.translate.x)}px`)
      style.setProperty('--particle-translate-y', `${this.getRandomNumberPlusMinusUpTo(this.config.translate.y)}px`)

      this.root.appendChild(particle)

      return particle
    }

    getRandomNumberBetween(min = 0, max = 100) {
      min = Math.ceil(min)
      max = Math.floor(max)
      return Math.floor(Math.random() * (max - min + 1)) + min
    }

    getRandomNumberUpTo(value = 100) {
      return Math.floor(Math.random() * value + 1)
    }

    getRandomNumberPlusMinusUpTo(value = 10) {
      return (Math.random() - .5) * this.getRandomNumberUpTo(value)
    }

    getRootDimensions() {
      return {
        height: this.root.offsetHeight,
        width: this.root.offsetWidth
      }
    }

  }

})(document)

export { WCParticle }
