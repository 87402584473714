//
// Web Components.
// Particle Button.
// --------------------

import { WCParticle } from './particle.js'

const WCParticleButton = ((document) => {

  return class ParticleButton {

    constructor(root, bound = false) {
      this.root = root
      this.bound = this.checkBoundValue(bound)
      this.count = parseInt(root.dataset.particlesCount || 20, 10)

      const multiplier = this.bound ? 1.75 : 1.25
      const to = this.maxSize() * multiplier

      this.particle = new WCParticle(root, {
        size: { max: 7, min: 5 },
        duration: { max: 600, min: 240 },
        translate: { x: to, y: to }
      })

      this.clickEventHandler = event => this.eventHandler(event)

      this.root.addEventListener('click', this.clickEventHandler)
    }

    checkBoundValue(bound) {
      return bound || this.root.dataset.particles === 'bound'
    }

    clickPosition(event) {
      let { clientX, clientY } = event
      const root = this.root.getBoundingClientRect()

      if (clientX === 0 || clientY === 0 || this.bound) {
        clientX = root.width / 2
        clientY = root.height / 2
      } else {
        clientX = clientX - root.left
        clientY = clientY - root.top
      }

      return { x: clientX, y: clientY }
    }

    eventHandler(event) {
      let { x, y } = this.clickPosition(event)

      for (let i = 0; i < this.count; i++)
        this.particle.animate(x, y)
    }

    maxSize() {
      let w = Math.round(this.root.offsetWidth)
      let h = Math.round(this.root.offsetHeight)

      if (w === h && ! this.bound)
        return w * 1.5
      
      return Math.max(w, h)
    }

  }

})(document)

export { WCParticleButton }