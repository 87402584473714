//
// Web Components.
// Carousel.
// -------------------

import { pageVisibility } from '../web-components/utils.js'
import { WCSwipe } from '../swipe/swipe.js'

const WCCarousel = ((document, window) => {

  function setDefaultAriaAttributes (element, index, current) {
    element.setAttribute(
      'aria-selected',
      current === index ? 'true' : 'false'
    )
    element.setAttribute(
      'aria-hidden',
      current === index ? 'false' : 'true'
    )
  }

  const NAME = 'carousel'

  return class Carousel extends WCSwipe {

    constructor(root) {
      super(root)

      if ( ! this.root) return

      this.wrapper = this.root.querySelector(`.${NAME}__wrapper`)
      this.items = [...this.root.querySelectorAll(`.${NAME}__item`)]

      this.buttonNext = this.root.querySelector(`[data-carousel-next]`)
      this.buttonPrev = this.root.querySelector(`[data-carousel-prev]`)

      const idx = this.items.findIndex(
        (c) => c.getAttribute('aria-selected') === 'true'
      )

      this.total_ = this.items.length
      this.current_ = idx > -1 ? idx : 0
      this.autoplay_ = this.root.dataset.carouselAutoplay === 'true' || false
      this.duration_ = parseInt(this.root.dataset.carouselDuration || 5800, 10)

      this.root.tabIndex = 0
      this.root.dataset.carouselPlaying = 'false'

      this.items.forEach(
        (e, i) => setDefaultAriaAttributes(e, i, this.current_)
      )

      this.itemNextHandler_ = () => this.next()
      this.itemPrevHandler_ = () => this.prev()
      this.keyDownNavigationHandler_ = (event) => this.keyDownNavigation_(event)

      if (this.buttonNext && this.buttonPrev) {
        this.buttonNext.addEventListener('click', this.itemNextHandler_)
        this.buttonPrev.addEventListener('click', this.itemPrevHandler_)
      }

      this.root.addEventListener('keydown', this.keyDownNavigationHandler_)
      this.root.addEventListener('swipe:left', this.itemNextHandler_)
      this.root.addEventListener('swipe:right', this.itemPrevHandler_)

      if (this.autoplay_)
        this.play()

      if (this.autoplay_)
        this.pageVisibility_()
    }

    get currentItem() {
      return this.items[this.current_]
    }

    next() {
      this.goToItem_(
        'next',
        this.current_ < this.total_ - 1
        ? this.current_ + 1 : 0
      )
    }

    play() {
      if (this.interval_)
        window.clearInterval(this.interval_)

      this.interval_ = window.setInterval(
        () => this.next(), this.duration_
      )

      this.root.dataset.carouselPlaying = 'true'
    }

    prev() {
      this.goToItem_(
        'prev',
        this.current_ > 0
        ? this.current_ - 1
        : this.total_ - 1
      )
    }

    stop() {
      if (this.interval_)
        window.clearInterval(this.interval_)

      this.root.dataset.carouselPlaying = 'false'

      this.interval_ = false
    }

    goToItem_(direction = 'auto', index = 0) {
      let goto = this.items[index]
      let current = this.currentItem

      if (index === this.current_)
        return

      if (this.root.dataset.carouselPlaying === 'true') {
        this.stop()
        this.play()
      }

      ;[
        `${NAME}__item--from-left`,
        `${NAME}__item--from-right`,
        `${NAME}__item--to-left`,
        `${NAME}__item--to-right`
      ].forEach((c) => {
        goto.classList.remove(c)
        current.classList.remove(c)
      })

      if (direction === 'auto')
        direction = index < this.current_ ? 'prev' : 'next'

      if (direction === 'prev') {
        goto.classList.add(`${NAME}__item--from-left`)
        current.classList.add(`${NAME}__item--to-right`)
      }

      if (direction === 'next') {
        goto.classList.add(`${NAME}__item--from-right`)
        current.classList.add(`${NAME}__item--to-left`)
      }

      goto.setAttribute('aria-hidden', 'false')
      goto.setAttribute('aria-selected', 'true')
      current.setAttribute('aria-hidden', 'true')
      current.setAttribute('aria-selected', 'false')

      this.current_ = index
    }

    keyDownNavigation_(event) {
      const { key, which } = event

      if (
        key === 'ArrowLeft'
        || which === 37
      ) return this.prev()

      if (
        key === 'ArrowRight'
        || which === 39
      ) return this.next()
    }

    pageVisibility_(use = true) {
      const visibility = pageVisibility()

      const visibilityHandler = () => {
        if (document[visibility.hidden])
          return this.stop()

        return this.play()
      }

      if (use) document.addEventListener(visibility.event, visibilityHandler)
      else document.removeEventListener(visibility.event, visibilityHandler)
    }

  }

})(document, window)

export { WCCarousel }
