//
// Web Components.
// JS Utils.
// -------------------

export function emitEvent (element, name, detail = {}) {
  element.dispatchEvent(new CustomEvent(name, { detail }))
}

// https://stackoverflow.com/a/48218209
export function extend (...objects) {
  const isObject = obj => obj && typeof obj === 'object'

  return objects.reduce((prev, obj) => {
    Object.keys(obj).forEach(key => {
      const pVal = prev[key]
      const oVal = obj[key]

      if (Array.isArray(pVal) && Array.isArray(oVal))
        prev[key] = oVal
      else if (isObject(pVal) && isObject(oVal))
        prev[key] = extend(pVal, oVal)
      else
        prev[key] = oVal
    })

    return prev
  }, {})
}

export function pageVisibility () {
  const prefixes = ['webkit','moz','ms','o']
  let hidden = false
  let prefix = false

  if ('hidden' in document)
    hidden = 'hidden'

  for (let i = 0; i < prefixes.length; i++)
    if (`${prefixes[i]}Hidden` in document) {
      hidden = `${prefixes[i]}Hidden`
      prefix = prefixes[i]
    }

  hidden = hidden || false

  const event = `${prefix || ''}visibilitychange`

  return {
    event, hidden, prefix
  }
}
